// extracted by mini-css-extract-plugin
export var category = "services-module--category--30ab9";
export var description = "services-module--description--1caf8";
export var divider = "services-module--divider--35ab0";
export var item = "services-module--item--c50d6";
export var link = "services-module--link--fd5eb";
export var meta = "services-module--meta--09a03";
export var more = "services-module--more--249f0";
export var postDivider = "services-module--postDivider--c3a2b";
export var services = "services-module--services--318ac";
export var time = "services-module--time--c281d";
export var title = "services-module--title--9b214";