import React from "react";

import { Link } from "gatsby";

import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { Props } from "@/types"; // Import the Props type

import * as styles from "./services.module.scss";

const calendlyLink = "https://calendly.com/henry-christopher/introductory-call";

const ServicesPage: React.FC<Props> = () => (
  <Layout>
    <Sidebar />
    <Page title="My Services">
      <div className={styles.services}>
        <div className={styles.item}>
          <div className={styles.meta}>
            <span className={styles.time}>For Engineering Leaders</span>
            <span className={styles.divider} />
            <span className={styles.category}>
              <Link to={calendlyLink} className={styles.link}>
                Book intro
              </Link>
            </span>
          </div>
          <p className={styles.description}>
            <Link to={calendlyLink}>
              Are you an engineering leader struggling to make an impact? Master
              the art of tech management and elevate your career with a clear
              path towards impactful leadership
            </Link>
          </p>
          <hr className={styles.postDivider} />
        </div>

        <div className={styles.item}>
          <div className={styles.meta}>
            <span className={styles.time}>For Startups</span>
            <span className={styles.divider} />
            <span className={styles.category}>
              <Link to={calendlyLink} className={styles.link}>
                Book intro
              </Link>
            </span>
          </div>
          <p className={styles.description}>
            <Link to={calendlyLink}>
              Are you a startup facing turbulence on the runway to success? Tell
              me what's happening and I can help your team remove roadblocks and
              increase velocity.
            </Link>
          </p>
          <hr className={styles.postDivider} />
        </div>

        <div className={styles.item}>
          <div className={styles.meta}>
            <span className={styles.time}>For Founders</span>
            <span className={styles.divider} />
            <span className={styles.category}>
              <Link to={calendlyLink} className={styles.link}>
                Book intro
              </Link>
            </span>
          </div>
          <p className={styles.description}>
            <Link to={calendlyLink}>
              Are you a non-technical founder drowning in the sea of tech
              buzzwords? Ask me anything and I will help you bootstrap your
              prototype or MVP.
            </Link>
          </p>
          <hr className={styles.postDivider} />
        </div>

      </div>
    </Page>
  </Layout>
);

// eslint-disable-next-line no-undef
export const Head: React.FC<Props> = () => (
  <Meta title="My Services" description="My Services" />
);

export default ServicesPage;
